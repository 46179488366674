'use client';

/* This example requires Tailwind CSS v2.0+ */
import PropTypes, { InferProps } from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
export const OTPCodeInputProps = {
  length: PropTypes.number,
  onFinishCode: PropTypes.func.isRequired
};
let currentOTPIndex = 0;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function OTPCodeInput({
  length,
  onFinishCode
}: InferProps<typeof OTPCodeInputProps> & {
  onFinishCode: (value: string) => void;
}) {
  const [OTP, setOTP] = useState<Array<string>>(new Array(length || 4).fill(''));
  const [activeOTPIndex, setActiveOTPIndex] = useState<number>(0);
  const inputRef = useRef<HTMLInputElement>(null);
  const handleCodeChange = useCallback(({
    target
  }: React.ChangeEvent<HTMLInputElement>) => {
    const newOTP: Array<string> = [...OTP];
    const {
      value
    } = target;
    newOTP[currentOTPIndex] = value.substring(value.length - 1);
    if (!value) {
      setActiveOTPIndex(currentOTPIndex - 1);
    } else {
      setActiveOTPIndex(currentOTPIndex + 1);
    }
    setOTP(newOTP);
    if (newOTP.every(digit => Boolean(digit))) {
      onFinishCode(newOTP.join(''));
    } else {
      onFinishCode('');
    }
  }, [OTP, onFinishCode]);
  const handleOnkeyDown = ({
    key
  }: React.KeyboardEvent<HTMLInputElement>, elementIndex: number) => {
    currentOTPIndex = elementIndex;
    if (key === 'Backspace') {
      setActiveOTPIndex(currentOTPIndex - 1);
    }
  };
  useEffect(() => {
    inputRef.current?.focus();
  }, [activeOTPIndex]);
  return <div className="flex items-start justify-center space-x-2" data-sentry-component="OTPCodeInput" data-sentry-source-file="OTPCodeInput.tsx">
      {OTP.map((value, index) => {
      return (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index}>
            <input ref={index === activeOTPIndex ? inputRef : null} id={`otp${index}`} name={`otp${index}`} type="number" required className="otp-code-input spin-button-none" onChange={handleCodeChange} onKeyDown={e => handleOnkeyDown(e, index)} value={OTP[index] || value} />
          </div>
      );
    })}
    </div>;
}
export default OTPCodeInput;