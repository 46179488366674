import { getUserTimezone } from '@/utils/date.helper';
import { ofetch, FetchError } from 'ofetch';
import { z } from 'zod';

const urlSchema = z.string().url();

type URL = z.infer<typeof urlSchema>;

const FetchViaProxyOptionsSchema = z.object({
  method: z.enum(['GET', 'POST', 'PUT', 'PATCH', 'DELETE', 'OPTIONS']).optional(),
  body: z.record(z.string(), z.unknown()).optional(), // TODO: use right type
  query: z.record(z.string(), z.unknown()).optional(), // TODO: use right type
});

type HeadersWithCustom = HeadersInit & Record<string, string>;

export type FetchViaProxyOptions = z.infer<typeof FetchViaProxyOptionsSchema> & {
  headers?: HeadersWithCustom;
  // method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE' | 'OPTIONS';
};

type ProxyBody = FetchViaProxyOptions & { url?: URL | string };

export default async function fetchViaProxy(url: URL | string, options: FetchViaProxyOptions) {
  const opts: ProxyBody = options;

  opts.url = url;

  // try {
  return ofetch('/api/proxy', {
    method: 'POST',
    body: opts,
    headers: {
      'x-timezone': getUserTimezone(),
    },
  })
    .then((response) => response)
    .catch((e: FetchError) => {
      //eslint-disable-next-line no-console
      console.error(e.message);

      if (e.statusCode === 401) {
        return window.location.replace('/auth/login');
      }
    });

  //   return response;
  // } catch (e) {
  //   console.error(e.message);
  // }
}
