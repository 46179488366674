'use client';

/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-array-index-key */
import classNames from 'classnames';
import { ErrorMessage, Field, FieldProps } from 'formik';
import React, { useCallback, useState } from 'react';
import Select from 'react-select';
import { Get } from '@/utils/fetchers';
import { getSelectOptionsAndValues } from '@/utils/select';
import { SelectMenuPrimaryProps, SelectedOption } from './types';
import debounce from '@/utils/debounce';
import dynamic from 'next/dynamic';
import FakeReactSelect from './FakeReactSelect';
const SelectAsync = dynamic(() => import('react-select/async'), {
  ssr: false,
  loading: () => <FakeReactSelect placeholder="Select..." />
});
const SelectMenuPrimary = ({
  id,
  name,
  className,
  defaultValue,
  labelText,
  horizOnBigScreen,
  labelDescription,
  options,
  value: inputValue,
  useFormik,
  hideLabel,
  loading,
  optionsUrl,
  extractOptions,
  ...otherProps
}: SelectMenuPrimaryProps, ref: React.Ref<HTMLSelectElement>) => {
  const [selectedOption, setSelectedOption] = useState<SelectedOption | null>();

  // eslint-disable-next-line
  const loadOptions = useCallback(debounce(async (search: string, callback: (options: {
    label: string;
    value: string | number;
  }[]) => void) => {
    // setTimeout(async () => {
    if (search && optionsUrl && extractOptions) {
      const {
        data
      } = await Get<{
        data: unknown[];
      }>(`${optionsUrl}?search=${search}`);
      callback(extractOptions({
        data
      }));
    }
    // }, 50);
  }, 500), [optionsUrl, extractOptions]);
  const handleSelectOption = useCallback((opt: typeof selectedOption) => {
    setSelectedOption(opt);
  }, []);
  return <div className="mt-1" data-sentry-component="SelectMenuPrimary" data-sentry-source-file="SelectMenuPrimary.tsx">
      <div className={classNames(horizOnBigScreen ? 'max-w-4xl sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-gray-200 sm:pt-5' : '')}>
        <label htmlFor={id || name} className={classNames('block text-sm font-medium text-gray-700', hideLabel ? 'opacity-0' : '')}>
          {labelText}
        </label>
        {labelDescription && <p className="mt-1 text-xs text-gray-500">{labelDescription}</p>}
        {useFormik ? <>
            <Field name={id || name || 'select'}>
              {({
            field,
            form
          }: FieldProps<any>) => {
            const {
              placeholder,
              defaultVal,
              opts
            } = getSelectOptionsAndValues({
              defaultValue,
              options,
              value: inputValue,
              selectedOption
            });
            if (extractOptions && optionsUrl) {
              return <SelectAsync {...field} onBlur={() => form.setFieldTouched(field.name, true)} required={otherProps.required} defaultValue={selectedOption || defaultVal} value={selectedOption || defaultVal} isMulti={otherProps.multiple} name={id} defaultOptions={true} cacheOptions loadOptions={loadOptions} onChange={(newVal: any) => {
                const val: string | string[] = newVal?.map ? newVal.map(({
                  value
                }: any) => value) : newVal.value;
                form.setFieldValue(field.name, val);
                handleSelectOption(newVal);
              }} className={classNames(`text-sm ${className} `, horizOnBigScreen ? 'mt-1 sm:col-span-2 sm:mt-0' : 'mt-1')} classNames={{
                input: props => `${props.className} min-h-[36px] `
              }} placeholder={placeholder} classNamePrefix="select" isLoading={loading} />;
            }
            return <Select inputId={id || name} {...field} onBlur={() => form.setFieldTouched(field.name, true)} required={otherProps.required} defaultValue={selectedOption || defaultVal} value={selectedOption || defaultVal} isMulti={otherProps.multiple} name={id} options={opts} onChange={(newVal: any) => {
              const val: string | string[] = newVal?.map ? newVal.map(({
                value
              }: any) => value) : newVal.value;
              form.setFieldValue(field.name, val);
              handleSelectOption(newVal);
            }} className={classNames(`text-sm ${className} `, horizOnBigScreen ? 'mt-1 sm:col-span-2 sm:mt-0' : 'mt-1')} classNames={{
              input: props => `${props.className} min-h-[36px] `
            }} placeholder={placeholder} classNamePrefix="select" isLoading={loading} />;
          }}
            </Field>
            <ErrorMessage name={id || name || 'select'} render={errorMessage => {
          return errorMessage ? <div className="mt-1 text-xs text-red-400">{errorMessage}</div> : null;
        }} />
          </> : <select ref={ref} id={id} multiple={otherProps.multiple} name={id || name} className={`mt-1 block min-h-[45px] w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm ${className}`}
      // defaultValue={defaultValue}
      // eslint-disable-next-line react/jsx-props-no-spreading
      onClick={otherProps.onClick} onSelect={otherProps.onSelect}>
            {defaultValue && <option value="">{typeof defaultValue === 'object' ? '' : defaultValue}</option>}
            {options?.map((option, index) => {
          let label = option;
          let value = option;
          if (typeof option === 'object') {
            // eslint-disable-next-line prefer-destructuring
            label = option[0];
            // eslint-disable-next-line prefer-destructuring
            value = option[1];
          }
          return <option key={option.toString() + index} value={value}>
                  {label}
                </option>;
        })}
          </select>}
      </div>
    </div>;
};
export default React.forwardRef(SelectMenuPrimary);