'use client';

import { Eye, EyeOff } from 'react-feather';
export default function PasswordControl({
  inputType,
  showPassword,
  onClickControl
}: {
  inputType: string;
  showPassword: boolean;
  onClickControl: () => void;
}) {
  return <div data-sentry-component="PasswordControl" data-sentry-source-file="PasswordControl.tsx">
      {inputType === 'password' && <div className="absolute inset-y-0 right-0 mr-2 flex items-center">
          {showPassword ? <EyeOff onClick={() => onClickControl()} size={18} className="text-gray-500" /> : <Eye onClick={() => onClickControl()} size={18} className="text-gray-500" />}
        </div>}
    </div>;
}