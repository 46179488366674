'use client';

/* eslint-disable no-nested-ternary */
/* This example requires Tailwind CSS v2.0+ */
import classNames from 'classnames';
import { ErrorMessage, Field, FieldProps } from 'formik';
import React, { useState } from 'react';
import DatePickerInput from './DatePickerInput';
import PasswordControl from './PasswordControl';
import { z } from 'zod';
import cn from '@/utils/cn';
export const TextInputPrimaryPropsSchema = z.object({
  labelFor: z.string().optional(),
  id: z.string(),
  name: z.string().optional(),
  labelText: z.string(),
  type: z.string(),
  autoComplete: z.string(),
  required: z.boolean().optional(),
  useFormik: z.boolean().optional(),
  hideLabel: z.boolean().optional(),
  removeLabel: z.boolean().optional(),
  className: z.string().optional(),
  errorClassName: z.string().optional(),
  placeholder: z.string().optional(),
  rows: z.number().optional(),
  value: z.string().optional(),
  horizOnBigScreen: z.boolean().optional(),
  prefix: z.string().optional(),
  postfix: z.string().optional()
});
export type TextInputPrimaryProps = z.infer<typeof TextInputPrimaryPropsSchema>;
function TextInputPrimary({
  prefix,
  postfix,
  useFormik,
  labelFor,
  horizOnBigScreen,
  id,
  name,
  type: _type,
  labelText,
  autoComplete,
  required,
  className,
  errorClassName,
  hideLabel,
  removeLabel,
  placeholder,
  rows,
  value,
  defaultValue,
  ..._otherProps
}: TextInputPrimaryProps & React.ComponentProps<'input'>) {
  const [showPassword, setShowPassword] = useState(false);
  const otherProps: React.ComponentProps<'input'> = _otherProps;
  let val = value;
  let defaultVal = defaultValue;
  let type = _type;
  if (_type === 'password') {
    if (showPassword) {
      type = 'text';
    } else {
      type = 'password';
    }
  }
  if (defaultValue || typeof value === 'undefined') {
    val = undefined;
  }
  if (value || typeof defaultValue === 'undefined') {
    defaultVal = undefined;
  }
  return <div className="mt-1" data-sentry-component="TextInputPrimary" data-sentry-source-file="TextInputPrimary.tsx">
      <div className={classNames(horizOnBigScreen ? 'max-w-4xl sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-gray-200 sm:pt-5' : '')}>
        {!removeLabel && <label htmlFor={labelFor || id} className={classNames('block text-sm font-medium text-gray-700', hideLabel ? 'opacity-0' : '')}>
            {labelText}
          </label>}
        <div className={classNames(horizOnBigScreen ? 'mt-1 sm:col-span-2 sm:mt-0' : 'mt-1')}>
          {useFormik ? <>
              <div className="relative">
                {type === 'date' ? <Field name={name || id}>
                    {({
                form
              }: FieldProps<{
                id: string;
              }>) => <DatePickerInput id={name || id} selectedDate={val || ''} onSelectDate={newDate => form.setFieldValue(id, newDate)} />}
                  </Field> : <div className={cn(type !== 'color' ? 'flex w-auto rounded-md shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600' : 'w-full')}>
                    {prefix && <span className="flex select-none items-center pl-3 text-gray-500 sm:text-sm">
                        {prefix}
                      </span>}
                    <Field id={id} name={name || id} type={type} as={type === 'textarea' ? type : undefined} rows={type === 'textarea' ? rows || 4 : undefined} placeholder={placeholder || ''} autoComplete={autoComplete} required={Boolean(required)} className={cn('text-input-primary', type !== 'color' && 'block w-auto flex-1 border-0 bg-transparent px-3 py-2.5 pl-3 focus:ring-0 sm:text-sm sm:leading-6', Boolean(postfix || prefix) && 'border-none shadow-none', className)} {...otherProps} value={val} defaultValue={defaultVal} />

                    <PasswordControl inputType={_type} showPassword={showPassword} onClickControl={() => setShowPassword(p => !p)} />
                    {postfix && <span className="flex select-none items-center pr-3 text-gray-500 sm:text-sm">
                        {postfix}
                      </span>}
                  </div>}
              </div>

              <ErrorMessage name={name || id} render={errorMessage => {
            return errorMessage ? <div className={classNames('mt-1 text-xs text-red-400', errorClassName)}>
                      {errorMessage}
                    </div> : <div />;
          }} />
            </> : type === 'textarea' ? <textarea rows={4} name="comment" id="comment" className="block min-h-[45px] w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm" defaultValue="" /> : <div className="relative">
              <input id={id} name={name || id} type={type} placeholder={placeholder || ''} autoComplete={autoComplete} required={Boolean(required)} className={`block min-h-[45px] w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-blue-500 focus:outline-none focus:ring-blue-500 sm:text-sm ${className}`}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...otherProps} />
              <PasswordControl inputType={_type} showPassword={showPassword} onClickControl={() => setShowPassword(p => !p)} />
            </div>}
        </div>
      </div>
    </div>;
}
export default TextInputPrimary;