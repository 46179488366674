/* eslint-disable import/prefer-default-export */
import { SelectMenuPrimaryProps, SelectedOption } from '@/ui/Select/types';

type GetSelectOptionsAndValueProps = {
  value: SelectMenuPrimaryProps['value'];
  defaultValue: SelectMenuPrimaryProps['defaultValue'];
  options: SelectMenuPrimaryProps['options'];
  selectedOption?: SelectedOption | null;
};

export const getSelectOptionsAndValues = ({
  value: inputValue,
  defaultValue,
  options,
  selectedOption,
}: GetSelectOptionsAndValueProps) => {
  let defaultVal: typeof selectedOption;

  let placeholder = 'Select an Option...';

  const opts = options?.map((option) => {
    if (typeof option === 'object') {
      // eslint-disable-next-line prefer-destructuring
      const label = option[0] || '';
      // eslint-disable-next-line prefer-destructuring
      const value = option[1] || '';
      return { label, value };
    }
    return { label: option, value: option };
  });

  if (!inputValue) defaultVal = undefined;

  if (Array.isArray(inputValue) && inputValue.length === 2) {
    defaultVal = {
      label: inputValue[0],
      value: inputValue[1],
    };
    placeholder = defaultVal.label;
  } else if (typeof inputValue === 'string') {
    const label =
      opts?.find((opt) => opt?.value?.toString() === inputValue.toString())?.label || inputValue;
    defaultVal = {
      label,
      value: inputValue,
    };
    placeholder = defaultVal.label;
  }

  if (!inputValue) defaultVal = undefined;

  placeholder =
    defaultValue && typeof defaultValue === 'object' ? '' : defaultValue?.toString() || placeholder;

  return { placeholder, defaultVal, opts };
};
