'use client';

import { Field } from 'formik';
import PropTypes, { InferProps } from 'prop-types';
import React from 'react';
const defaultCheckboxPrimaryProps = {
  id: PropTypes.string.isRequired,
  useFormik: PropTypes.bool,
  containerClassName: PropTypes.string,
  onCheckBoxChange: PropTypes.func
};
export type CheckboxPrimaryProps = InferProps<typeof defaultCheckboxPrimaryProps> & React.ComponentProps<'input'> & {
  labelText: React.ReactNode;
};
export default function CheckboxPrimary({
  id,
  name,
  className,
  containerClassName,
  labelText,
  useFormik,
  ..._otherProps
}: CheckboxPrimaryProps) {
  const otherProps: React.ComponentProps<'input'> = _otherProps;
  return <div className={`flex items-center ${containerClassName}`} data-sentry-component="CheckboxPrimary" data-sentry-source-file="CheckboxPrimary.tsx">
      {useFormik ? <Field id={id} name={name || id} type="checkbox" className={`h-4 w-4 rounded border-gray-300 text-primary-default focus:ring-primary-default ${className}`} {...otherProps} /> : <input id={id} name={name || id} type="checkbox" onChange={otherProps.onChange} className={`h-4 w-4 rounded border-gray-300 text-primary-default focus:ring-primary-default ${className}`} value={otherProps.value}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...otherProps} />}
      <label htmlFor={id || name} className="ml-2 block text-sm text-gray-900">
        {labelText as string}
      </label>
    </div>;
}