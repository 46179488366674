'use client';

/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-redundant-roles */
import classNames from 'classnames';
import { useCallback, useEffect, useRef } from 'react';
type NumberStepProps = {
  steps: {
    label?: string;
    description?: string;
  }[];
  activeStepIndex: number;
  onClickStep: (stepIndex: number) => void;
};
export default function NumberStep({
  activeStepIndex,
  steps,
  onClickStep
}: NumberStepProps) {
  const listItemRef: React.LegacyRef<HTMLLIElement> = useRef(null);
  const handleClickStep = useCallback((stepIndex: number) => {
    onClickStep(stepIndex);
  }, [onClickStep]);
  useEffect(() => {
    if (listItemRef.current) {
      listItemRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      });
    }
  }, [activeStepIndex]);
  return <nav aria-label="Progress" data-sentry-component="NumberStep" data-sentry-source-file="NumberStep.tsx">
      <ol role="list" className="overflow-hidden">
        {steps.map((step, stepIdx) => {
        // const isDone = activeStepIndex > stepIdx;
        const isCurrent = activeStepIndex === stepIdx;
        return <li ref={isCurrent ? listItemRef : null}
        // eslint-disable-next-line react/no-array-index-key
        key={(step?.label || '') + stepIdx} className={classNames(stepIdx !== steps.length - 1 ? 'pb-10' : '', 'relative')}>
              {stepIdx !== steps.length - 1 ? <div className="absolute left-4 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-500" aria-hidden="true" /> : null}
              <div className="group relative flex items-center" role="button" aria-hidden="true" onClick={() => handleClickStep(stepIdx)}>
                <span className="flex h-9 items-center">
                  <span className={classNames(`relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2`, isCurrent ? 'border-gray-600 bg-gray-100 font-medium text-gray-800' : 'border-gray-300 bg-gray-100 text-gray-400')}>
                    {stepIdx + 1}
                  </span>
                </span>
                <span className="ml-4 flex min-w-0 flex-col">
                  {step.label && <span className={classNames('text-sm', isCurrent ? 'font-semibold text-gray-700' : 'font-medium text-gray-500')}>
                      {step.label}
                    </span>}
                  {step.description && <span className={classNames('text-sm', isCurrent ? 'text-gray-600' : 'text-gray-400')}>
                      {step.description}
                    </span>}
                </span>
              </div>
            </li>;
      })}
      </ol>
    </nav>;
}