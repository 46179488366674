// To navigate to a new page and keep the current page in history
const push = (url: string) => {
  window.location.href = url;
};

const replace = (url: string) => {
  window.location.href = url;
};

const Router = { push, replace };

export default Router;
