'use client';

/* eslint-disable jsx-a11y/no-redundant-roles */
import classNames from 'classnames';
import React, { useEffect, useRef } from 'react';
import { ChevronRight } from 'react-feather';
import Link from 'next/link';
import cn from '@/utils/cn';
export type Pages = Array<{
  name: string;
  href: string;
  isCurrent?: boolean;
  number: number;
  onClickPage?: () => void;
  className?: string;
}>;
type BreadcrumbProps = {
  pages: Pages;
  currentPage: number;
};
export default function Breadcrumb({
  className,
  pages,
  currentPage
}: React.ComponentProps<'div'> & BreadcrumbProps) {
  const listItemRef: React.LegacyRef<HTMLLIElement> = useRef(null);
  useEffect(() => {
    if (listItemRef.current) {
      listItemRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      });
    }
  }, [currentPage]);
  return <nav className={classNames('flex justify-center', className)} aria-label="Breadcrumb" data-sentry-component="Breadcrumb" data-sentry-source-file="Breadcrumb.tsx">
      <ol role="list" className={cn('scrollbar-primary mx-auto flex items-center space-x-3 overflow-x-auto py-3', className)}>
        {pages.map((page, index) => {
        const isCurrentPage = currentPage === index + 1 || page.isCurrent;
        return <li key={page.name} ref={isCurrentPage ? listItemRef : null}>
              <div className="flex items-center">
                {index > 0 && <ChevronRight className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />}
                <Link href={page.href} onClick={() => page.onClickPage?.call(0)} className={classNames('ml-3 flex items-center text-xs hover:text-gray-700 sm:text-sm', isCurrentPage ? 'font-medium text-gray-700' : 'text-gray-400')} aria-current={isCurrentPage ? 'page' : undefined}>
                  <div className="mr-2">
                    <div className={classNames('flex h-6 w-6 items-center justify-center rounded-full text-center text-xs', isCurrentPage ? 'bg-primary-default text-white' : 'bg-gray-100 text-gray-700')}>
                      {page.number}
                    </div>
                  </div>
                  {page.name}
                </Link>
              </div>
            </li>;
      })}
      </ol>
    </nav>;
}