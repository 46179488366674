'use client';

import { XMarkIcon } from '@heroicons/react/24/outline';
import { InferProps } from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import SelectMenuPrimary from './SelectMenuPrimary';
import { SelectMenuPrimaryProps } from './types';

// import { SelectMenuPrimary, SelectMenuPrimaryProps } from '@/ui';

export type SelectedItem = {
  value: string;
  text: string;
};
export const SelectItemsProps = {};
function SelectItems({
  options,
  required,
  labelText,
  autoComplete,
  id,
  value,
  selectedItemsRef,
  useFormik
}: InferProps<typeof SelectItemsProps> & SelectMenuPrimaryProps & {
  selectedItemsRef: React.MutableRefObject<unknown[]>;
}) {
  const [selectedItems, setSelectedItems] = useState<Array<SelectedItem>>([]);
  const handleRemoveItem = useCallback((item: SelectedItem) => {
    setSelectedItems(p => {
      const updatedItems = p.filter(i => i.value !== item.value);
      if (selectedItemsRef && selectedItemsRef.current) {
        const updatedStringOrObjItems = updatedItems.map(i => i?.value || i);

        // eslint-disable-next-line no-param-reassign
        selectedItemsRef.current = updatedStringOrObjItems;
      }
      return updatedItems;
    });
  }, [selectedItemsRef]);
  const handleSelectItem: React.MouseEventHandler<HTMLSelectElement> = useCallback(({
    currentTarget
  }) => {
    // const option = currentTarget.options[currentTarget.selectedIndex];
    // const selected = { value: option.value, text: option.text };
    const multipleSelected = Array.from({
      length: currentTarget.selectedOptions.length
    }).map((_, selectedIndex) => {
      const optionFromMultiple = currentTarget.selectedOptions.item(selectedIndex);
      return {
        value: optionFromMultiple?.value || '',
        text: optionFromMultiple?.text || ''
      };
    });
    // (p) =>
    //   [...p.filter((i) => multipleSelected.every((it) => it?.value !== i.value))].concat(
    //     multipleSelected.filter((s) => s.value)
    //   );
    if (selectedItemsRef && selectedItemsRef.current) {
      // eslint-disable-next-line no-param-reassign
      selectedItemsRef.current = multipleSelected.map(i => i.value);
    }
    setSelectedItems(multipleSelected);
  }, [selectedItemsRef]);
  useEffect(() => {
    if (value) {
      const multipleSelected = (options as string[][]).filter((opt: string | string[]) => (value as string[])?.some(v => {
        if (typeof opt === 'string') {
          return v === opt;
        }
        return v.toString() === opt[1]?.toString();
      })).map(item => {
        if (typeof item === 'string') {
          return {
            text: item,
            value: item
          };
        }
        return {
          text: item[0],
          value: item[1]
        };
      });
      if (selectedItemsRef && selectedItemsRef.current) {
        // eslint-disable-next-line no-param-reassign
        selectedItemsRef.current = multipleSelected.map(i => i.value);
      }
      setSelectedItems(multipleSelected);
    }
  }, [options, selectedItemsRef, value]);
  return <div className="text-xs" data-sentry-component="SelectItems" data-sentry-source-file="SelectItems.tsx">
      <SelectMenuPrimary value={value} useFormik={useFormik} id={id} autoComplete={autoComplete} labelText={labelText} required={required} options={options} onClick={handleSelectItem} multiple data-sentry-element="SelectMenuPrimary" data-sentry-source-file="SelectItems.tsx" />
      <p className="mt-2 hidden italic text-gray-400 sm:flex">
        You may need to hold down the Ctrl (windows) or Command (Mac) button to select multiple
        options.
      </p>
      <div className="mt-5 flex flex-wrap justify-start">
        {selectedItems.map(item => {
        return <div className="mr-2 mt-2 flex items-center rounded-lg border border-gray-300 bg-slate-50 p-1.5 text-xs" key={item.value + item.text}>
              {item.text}
              <XMarkIcon className="ml-2 h-3 w-3" onClick={() => handleRemoveItem(item)} aria-hidden="true" />
            </div>;
      })}
      </div>
    </div>;
}
export default SelectItems;