'use client';

import fetchViaProxy from './fetchViaProxy';
import { ReturnResponse } from '@/hooks/useProcessAsync';
import { getAsPath } from '@/utils/getAsPath';
import Router from '@/utils/windowRouter';

function parseResponse(response: any) {
  if ([401].some((code) => code === response.status)) {
    localStorage.setItem('authRedirect', getAsPath());

    return Router.replace('/auth/logout');
  }

  if (response) return response as ReturnResponse;

  return { errors: [], message: 'Hmmm...could not process!', status: 'error' };
}

const callFetch = async ({ url, options }: { url: string; options: any }) => {
  const passedOptions = options || {};

  if ('body' in passedOptions) {
    passedOptions['body'] = url.startsWith('/api')
      ? JSON.stringify(passedOptions?.body)
      : passedOptions?.body;
  }

  const response = url.startsWith('/api')
    ? (await fetch(url, passedOptions)).json()
    : await fetchViaProxy(url, passedOptions);

  return url.startsWith('/api') ? response : parseResponse(response);
};

// eslint-disable-next-line prettier/prettier
export const Get = async <T>(url: string, options?: any): Promise<T> => {
  const passedOptions = {
    method: 'GET',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    ...options,
  };

  return callFetch({ url, options: passedOptions }) as T;
};

// eslint-disable-next-line prettier/prettier
export const Post = async <T>(url: string, options?: any): Promise<T> => {
  const passedOptions = {
    method: 'POST',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    ...options,
    body: options?.body || undefined,
  };

  return callFetch({ url, options: passedOptions }) as T;
};

// eslint-disable-next-line prettier/prettier
export const Put = async <T>(url: string, options?: any): Promise<T> => {
  const passedOptions = {
    method: 'PUT',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    ...options,
    body: options?.body || undefined,
  };

  return callFetch({ url, options: passedOptions }) as T;
};

// eslint-disable-next-line prettier/prettier
export const Patch = async <T>(url: string, options?: any): Promise<T> => {
  const passedOptions = {
    method: 'PATCH',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    ...options,
    body: options?.body || undefined,
  };

  return callFetch({ url, options: passedOptions }) as T;
};

// eslint-disable-next-line prettier/prettier
export const Delete = async <T>(url: string, options?: any): Promise<T> => {
  const passedOptions = {
    method: 'DELETE',
    headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    ...options,
    body: options?.body || undefined,
  };

  return callFetch({ url, options: passedOptions }) as T;
};

export default {
  Get,
  Put,
  Post,
  Patch,
  Delete,
};
