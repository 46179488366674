'use client';

import React, { ChangeEventHandler, Fragment, useRef, useState } from 'react';
import { format, isValid, parse } from 'date-fns';
import { DayPicker, SelectSingleEventHandler } from 'react-day-picker';
import { Popover, Transition } from '@headlessui/react';
import { Calendar } from 'react-feather';
type DatePickerFieldProps = {
  id: string;
  onSelectDate: (value: string) => void;
  selectedDate: string;
};
export default function DatePickerInput({
  id,
  selectedDate,
  onSelectDate
}: DatePickerFieldProps) {
  const [selected, setSelected] = useState<Date>();
  const [inputValue, setInputValue] = useState<string>('');
  const buttonRef = useRef<HTMLButtonElement>(null);
  const closePopper = () => {
    buttonRef?.current?.click();
  };
  const handleInputChange: ChangeEventHandler<HTMLInputElement> = e => {
    const stringVal = e.currentTarget.value;
    setInputValue(stringVal);
    const date = parse(e.currentTarget.value, 'y-MM-dd', new Date());
    if (isValid(date)) {
      onSelectDate(stringVal);
      setSelected(date);
    } else {
      setSelected(undefined);
    }
  };
  const handleDaySelect: SelectSingleEventHandler = date => {
    setSelected(date);
    if (date) {
      const stringVal = format(new Date(date), 'y-MM-dd');
      setInputValue(stringVal);
      onSelectDate(stringVal);
      closePopper();
    } else {
      setInputValue('');
    }
  };
  return <Popover as="div" id={id} className="relative" data-sentry-element="Popover" data-sentry-component="DatePickerInput" data-sentry-source-file="DatePickerInput.tsx">
      <div className="flex w-full gap-x-6">
        <input size={12} className="text-input-primary flex-grow rounded-md border border-gray-300 px-3 py-2 shadow-sm" type="text" placeholder={selectedDate || 'YYY-MM-DD'} value={inputValue} onChange={handleInputChange} />
        <Popover.Button ref={buttonRef} className="rounded-lg border border-gray-400 p-3" data-sentry-element="unknown" data-sentry-source-file="DatePickerInput.tsx">
          <Calendar size={20} className="text-gray-500" data-sentry-element="Calendar" data-sentry-source-file="DatePickerInput.tsx" />
        </Popover.Button>
      </div>

      <Transition as={Fragment} enter="transition ease-out duration-100" enterFrom="transform opacity-0 scale-95" enterTo="transform opacity-100 scale-100" leave="transition ease-in duration-75" leaveFrom="transform opacity-100 scale-100" leaveTo="transform opacity-0 scale-95" data-sentry-element="Transition" data-sentry-source-file="DatePickerInput.tsx">
        <Popover.Panel className="inspect absolute right-0 z-10 mt-2 origin-top-right rounded-md border border-gray-200 bg-gray-50 ring-1 ring-black ring-opacity-5 focus:outline-none" data-sentry-element="unknown" data-sentry-source-file="DatePickerInput.tsx">
          <div className="text-sm" aria-label="DayPicker calendar">
            <DayPicker mode="single" defaultMonth={selected} selected={selected} onSelect={handleDaySelect} className="" data-sentry-element="DayPicker" data-sentry-source-file="DatePickerInput.tsx" />
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>;
}