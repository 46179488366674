'use client';

import classNames from 'classnames';
import React, { useState } from 'react';
import { Search } from 'react-feather';
export type SearchBarPrimaryProps = {
  onSearch: (searchQuery: string) => void;
  placeholder?: string;
  isSmall?: boolean;
  className?: string;
};
const SearchBarPrimary = ({
  onSearch,
  placeholder,
  isSmall,
  className
}: SearchBarPrimaryProps) => {
  const [searchValue, setSearchValue] = useState('');
  return <form className={classNames('flex flex-1 items-center')} onSubmit={e => {
    e.preventDefault();
    onSearch(searchValue);
  }} data-sentry-component="SearchBarPrimary" data-sentry-source-file="SearchBarPrimary.tsx">
      <label htmlFor="job-search" className="sr-only">
        {placeholder || 'Search'}
      </label>
      <div className={classNames('relative flex w-full flex-1')}>
        <div className={classNames(isSmall ? 'pl-2' : 'pl-3', `pointer-events-none absolute inset-y-0 left-0 flex items-center`)}>
          <Search size={isSmall ? 15 : 18} data-sentry-element="Search" data-sentry-source-file="SearchBarPrimary.tsx" />
        </div>
        <input type="text" id="job-search" className={classNames(isSmall ? 'p-2 pl-8' : 'p-2.5 pl-10', `focus:primary-default flex flex-1 rounded-lg border border-gray-300 bg-white text-sm text-gray-900 shadow lg:max-w-lg`, className)} placeholder={placeholder || 'Search'} onChange={e => {
        setSearchValue(e.target.value);
        onSearch(e.target.value);
      }} value={searchValue} required />
      </div>
    </form>;
};
export default SearchBarPrimary;