'use client';

import { FormikProps } from 'formik';
import dynamic from 'next/dynamic';
import React from 'react';
export type QuillPrimaryProps = {
  formikProps: FormikProps<any>;
  id: string;
  labelFor: string;
  labelText: string;
};
const QuillWrapper = dynamic(() => import('@/ui/QuillWrapper'), {
  ssr: false,
  loading: () => <div className="h-auto w-full border border-stone-300 bg-white text-sm font-normal text-gray-800">
      <div className="flex gap-x-4 border-b border-stone-300 p-3 font-semibold">
        <span className="pr-[3.5rem]">Normal</span>
        <span>B</span>
        <span>I</span>
        <span>U</span>
      </div>
      <div className="p-3 py-2 text-sm">Loading Editor...</div>
    </div>
});
const QuillPrimary = ({
  id,
  labelFor,
  labelText,
  formikProps
}: QuillPrimaryProps) => {
  const {
    errors,
    setFieldValue,
    values
  } = formikProps;
  return <div key={id} data-sentry-component="QuillPrimary" data-sentry-source-file="QuillPrimary.tsx">
      <label htmlFor={labelFor || id} className="mb-2 block text-sm font-medium text-gray-700">
        {labelText}
      </label>

      <QuillWrapper id={id} value={values[id]} onChange={val => setFieldValue(id, val)} data-sentry-element="QuillWrapper" data-sentry-source-file="QuillPrimary.tsx" />
      {errors[id] && <div className="mt-1 text-xs text-red-400">{errors[id]?.toString()}</div>}
    </div>;
};
export default QuillPrimary;