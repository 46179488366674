'use client';

import { Button } from '@/ui/Buttons/Button';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import { useRouter } from 'next/navigation';
import React from 'react';
import { ChevronLeft } from 'react-feather';
function BackButton({
  className,
  onClick
}: React.ComponentProps<'div'>) {
  const router = useRouter();
  return <div className={`mt-4 flex items-center ${className}`} data-sentry-component="BackButton" data-sentry-source-file="BackButton.tsx">
      <ChevronLeft className="mr-1 text-gray-800" fontSize="small" onClick={() => router.back()} data-sentry-element="ChevronLeft" data-sentry-source-file="BackButton.tsx" />
      <div role="button" tabIndex={0} onKeyDown={() => null} className="text-[15px] font-semibold text-gray-600" onClick={onClick || (() => router.back())}>
        Back
      </div>
    </div>;
}
export const BackBtnLink = ({
  className = '',
  onClick = () => null,
  title = '',
  href = '',
  ...props
}) => {
  const router = useRouter();
  return <Button {...props} href={href || undefined} variant="none" className={`text-semibold mt-4 flex items-center text-base ${className} `} onClick={onClick || (() => router.back())} data-sentry-element="Button" data-sentry-component="BackBtnLink" data-sentry-source-file="BackButton.tsx">
      <ChevronLeftIcon className="h-5 w-5" fontSize="small" data-sentry-element="ChevronLeftIcon" data-sentry-source-file="BackButton.tsx" />
      {title || 'Back'}
    </Button>;
};
export default BackButton;