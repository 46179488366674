'use client';

import classNames from 'classnames';
import PropTypes, { InferProps } from 'prop-types';
import React, { useCallback } from 'react';
export const tabListProps = {
  activeItem: PropTypes.string,
  tabItems: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired
};
export default function TabList({
  tabItems,
  activeItem,
  onSelectTabItem
}: InferProps<typeof tabListProps> & {
  onSelectTabItem: (value: string) => void;
}) {
  const onSelectTab = useCallback((item: string) => {
    onSelectTabItem(item);
  }, [onSelectTabItem]);
  return <div className="flex items-start justify-center space-x-2" data-sentry-component="TabList" data-sentry-source-file="TabList.tsx">
      <div className="mt-5 flex flex-col justify-center sm:mt-5">
        {/* <div className="flex flex-row self-center ">
          {[1, 2, 3].map((item) => {
            return (
              <div
                key={item}
                className={classNames(' h-auto w-auto  flex ', item === 3 ? '' : 'opacity-0')}>
                <Image src={logo} alt="Tech1M" />
              </div>
            );
          })}
         </div> */}

        <div className="flex justify-center self-center overflow-clip rounded-full border border-primary-default bg-white p-0.5">
          {tabItems.map(value => {
          const active = value === activeItem;
          return <button key={value} onClick={() => onSelectTab(value)} type="button" className={classNames(active ? `w-1/2 whitespace-nowrap rounded-full border border-gray-200 bg-primary-default px-3 py-2 text-sm font-medium text-white shadow-sm focus:z-10 sm:w-auto sm:px-16` : `w-1/2 whitespace-nowrap border border-transparent px-3 py-2 text-sm font-medium text-primary-default focus:outline-none focus:ring-2 sm:w-auto sm:px-16`, 'rounded-full capitalize')}>
                {value}
              </button>;
        })}
        </div>
      </div>
    </div>;
}